/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useLayoutEffect, useRef } from 'react';
import css from './input.module.css';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  icon?: JSX.Element;
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const { icon, ...restProps } = props;
  const inputRef = useRef(null);
  useLayoutEffect(() => {
    if (props.autoFocus && inputRef.current !== null) {
      // @ts-expect-error
      inputRef.current.focus();
    }
  }, [props.autoFocus]);

  return (
    <div className={css.container}>
      <span
        className={css.iconContainer}
        onClick={() => {
          // @ts-expect-error
          inputRef.current.focus();
        }}
      >
        {icon}
      </span>
      <input
        className={css.input}
        {...restProps}
        ref={inputRef}
        data-hj-allow
      />
    </div>
  );
};

export default Input;
