import React from 'react';
import InputName from './input-name';
import updateObjectInArray from 'lib/update-object-in-array';
import Button from 'components/common/button';
import { Name } from 'types/fields';
import { colors, defaults } from 'css/variables';
import { mod } from 'lib/mod';
import css from './input-names.module.css';

const undefinedIfEmpty = (names?: Name[]): Name[] | undefined => {
  // we want names to be undefined when they're empty so the citation date can go in the correct position for empty names
  if (!names) {
    return names;
  }
  if (
    names.length > 1 ||
    (names.length == 1 &&
      (names[0].given !== '' ||
        names[0].family !== '' ||
        names[0].suffix !== ''))
  ) {
    return names;
  }
};

interface InputNamesProps {
  citationId: string;
  isFirstNameField: boolean;
  label: string;
  name?: string;
  value: Name[];
  onChange: (newValue: Name[] | undefined) => void;
  isRequired?: boolean;
  isPrimary?: boolean;
  isFocused?: boolean;
}

const InputNames: React.FC<InputNamesProps> = (
  props: InputNamesProps,
): JSX.Element => {
  const handleChange = (newValue: Name, index: number): void => {
    const newObj = props.value
      ? updateObjectInArray(props.value, {
          item: newValue,
          index,
        })
      : [newValue];
    props.onChange(undefinedIfEmpty(newObj));
  };
  const handleClickRemove = (indexToRemove: number): void => {
    const newValue = (props.value || []).filter(
      (_, index) => index !== indexToRemove,
    );
    props.onChange(undefinedIfEmpty(newValue));
  };

  return (
    <div>
      <div className={css.inputsContainerInner}>
        {(
          props.value || [{ given: '', family: '', suffix: '', literal: '' }]
        ).map((value, index) => {
          const isFocused = props.isFocused && index === 0; // if we don't check for first index, it focuses last author

          return (
            <div
              className={css.fieldContainer}
              key={index}
              style={{ marginTop: index === 0 ? mod(0) : mod(1) }}
            >
              <InputName
                label={props.label}
                citationId={props.citationId}
                isRequired={props.isRequired}
                isFocused={isFocused}
                givenName={value.given}
                familyName={value.family}
                suffix={value.suffix}
                literalName={value.literal}
                backups={value.backups}
                onChange={(newValue) => handleChange(newValue, index)}
              />
              {(index !== 0 || !props.isFirstNameField) && (
                <Button
                  type="light"
                  size="small"
                  onClick={() => handleClickRemove(index)}
                  circle
                  style={{ margin: '0 24px 0 auto' }}
                >
                  ×
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InputNames;
