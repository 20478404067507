import React, { useRef, useState } from 'react';
import { LabelSmall } from 'components/common/typography';
import { BackUpNames, Name } from 'types/fields';
import InputText from './input-text';
import usePrevious from 'lib/hooks/usePrevious';
import FormLabel from './form-label';
import getAuthorNameFromString from 'lib/get-author-name-from-string';
import { specialCase } from 'lib/specialCase';
import css from './input-name.module.css';
import cx from 'classnames';

interface InputNameProps {
  citationId: string;
  givenName?: string;
  label: string;
  familyName?: string;
  suffix?: string;
  literalName?: string;
  isRequired?: boolean;
  isFocused?: boolean;
  backups?: BackUpNames;
  onChange: (newValue: Name) => void;
}

const getLiteralFromNameFields = (
  givenName: string | undefined,
  familyName: string | undefined,
  suffix: string | undefined,
): string => {
  if (givenName === undefined) givenName = '';
  if (familyName === undefined) familyName = '';
  if (suffix === undefined) suffix = '';

  if (familyName) {
    const space = givenName && ' ';
    familyName = space + familyName;
  }
  if (suffix) {
    const space = ((givenName && !familyName) || familyName) && ' ';
    suffix = space + suffix;
  }
  return `${givenName}${familyName}${suffix}`;
};

const InputName: React.FC<InputNameProps> = (
  props: InputNameProps,
): JSX.Element => {
  const [isLiteral, setIsLiteral] = useState(!!props.literalName);
  const givenName = useRef<HTMLInputElement>(null);
  const familyName = useRef<HTMLInputElement>(null);
  const suffix = useRef<HTMLInputElement>(null);
  const literalName = useRef<HTMLInputElement>(null);

  const applySuggestion = (typeSuggest?: string, suggestion?: string): void => {
    if (typeSuggest && suggestion) {
      let currentGivenName = givenName.current?.value;
      let currentFamilyName = familyName.current?.value;
      const currentSuffix = suffix.current?.value;
      const currentLiteralName = literalName.current?.value;

      // conditionally update
      if (typeSuggest === 'given') {
        currentGivenName = suggestion;
      }
      if (typeSuggest === 'family') {
        currentFamilyName = suggestion;
      }

      // send change
      props.onChange({
        given: currentGivenName,
        family: currentFamilyName,
        suffix: currentSuffix,
        literal: currentLiteralName,
        backups: {
          // save a backup in the citationData so when we switch to and from literal name, it preserves
          // the correct positioning of spaces in the name
          given: currentGivenName,
          family: currentFamilyName,
          suffix: currentSuffix,
        },
      });
    }
  };

  const handleChange = (): void => {
    const currentGivenName = givenName.current?.value;
    const currentFamilyName = familyName.current?.value;
    const currentSuffix = suffix.current?.value;
    const currentLiteralName = literalName.current?.value;
    props.onChange({
      given: currentGivenName,
      family: currentFamilyName,
      suffix: currentSuffix,
      literal: currentLiteralName,
      backups: {
        // save a backup in the citationData so when we swtich to and from literal name, it preserves
        // the correct positioning of spaces in the name
        given: currentGivenName,
        family: currentFamilyName,
        suffix: currentSuffix,
      },
    });
  };

  const toggleLiteral = (): void => {
    if (isLiteral) {
      const existingName = {
        family: props.backups?.family || props.familyName,
        given: props.backups?.given || props.givenName,
        suffix: props.backups?.suffix || props.suffix,
      };
      const newName =
        existingName.family || existingName.given || existingName.suffix
          ? existingName
          : getAuthorNameFromString(props.literalName || '');
      props.onChange(
        {
          ...newName,
          literal: undefined,
        } || {
          given: undefined,
          family: undefined,
          suffix: undefined,
        },
      );
    } else {
      const literalName =
        props.literalName ||
        getLiteralFromNameFields(
          props.givenName,
          props.familyName,
          props.suffix,
        );
      props.onChange({
        given: undefined,
        family: undefined,
        literal: literalName,
      });
    }
    setIsLiteral((isLiteral) => !isLiteral);
  };

  const previousCitationId = usePrevious(props.citationId);
  if (previousCitationId !== props.citationId) {
    const shouldSwitchToLiteral =
      (!props.givenName || !props.familyName) &&
      props.literalName &&
      !isLiteral;
    const shouldSwitchToRegular = !props.literalName && isLiteral;

    if (shouldSwitchToLiteral || shouldSwitchToRegular) {
      toggleLiteral();
    }
  }

  return (
    <div className={css.citationInputName}>
      <button className={css.toggleLink} onClick={() => toggleLiteral()}>
        <span
          className={cx(css.toggleLinkItem, {
            [css.toggleLinkItemActive]: !isLiteral,
          })}
        >
          Individual
        </span>
        <span
          className={cx(css.toggleLinkItem, {
            [css.toggleLinkItemActive]: isLiteral,
          })}
        >
          Organization
        </span>
      </button>
      {isLiteral ? (
        <div className={css.citationInputContainer}>
          <div className={css.citationNameTypeContainer}>
            <LabelSmall>{props.label}</LabelSmall>
          </div>
          <div className={css.inputContainer}>
            <FormLabel>Company/organization/group name:</FormLabel>
            <InputText
              inputRef={literalName}
              value={props.literalName || ''}
              isRequired={props.isRequired}
              isFocused={props.isFocused}
              onChange={handleChange}
              specialCase={specialCase.GROUP}
              applySuggestion={applySuggestion}
            />
          </div>
        </div>
      ) : (
        <div className={css.citationInputContainer}>
          <div className={css.citationNameTypeContainer}>
            <LabelSmall>{props.label}</LabelSmall>
          </div>
          <div className={css.inputContainer}>
            <FormLabel>First name(s)</FormLabel>
            <InputText
              inputRef={givenName}
              value={props.givenName || ''}
              onChange={handleChange}
              isRequired={props.isRequired}
              isFocused={props.isFocused}
              textCase="name"
              specialCase={specialCase.GIVEN}
              applySuggestion={applySuggestion}
            />
          </div>
          <div className={css.inputContainer}>
            <FormLabel>Last name</FormLabel>
            <InputText
              inputRef={familyName}
              value={props.familyName || ''}
              onChange={handleChange}
              isRequired={props.isRequired}
              isFocused={props.isFocused}
              textCase="name"
              specialCase={specialCase.FAMILY}
              applySuggestion={applySuggestion}
            />
          </div>
          <div className={css.inputContainer}>
            <FormLabel>Suffix</FormLabel>
            <InputText
              inputRef={suffix}
              style={{ width: 50 }}
              value={props.suffix || ''}
              onChange={handleChange}
              specialCase={specialCase.SUFFIX}
              applySuggestion={applySuggestion}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InputName;
