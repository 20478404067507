// @ts-ignore
import CSL from 'citeproc-js';

const HONORIFIC_TITLES = [
  'mr',
  'mrs',
  'ms',
  'esq',
  'sir',
  'mx',
  'dr',
  'mr.',
  'mrs.',
  'ms.',
  'esq.',
  'mx.',
  'dr.',
];

const NLP_TAGS_TO_CAPITALIZE = [
  'GPE',
  'ORG',
  'PERSON',
  'EVENT',
  'NORP',
  'FAC',
  'LOC',
  'LAW',
  'LANGUAGE',
  'PRODUCT',
  'WORK_OF_ART',
];

const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const hasCapitalsAfterFirstLetter = (str: string): boolean => {
  const afterFirstLetter = str.slice(1);
  return (
    afterFirstLetter !== afterFirstLetter.toLocaleLowerCase() &&
    !str.includes('-')
  );
};

const isFirstLetterAlpha = (str: string): boolean => {
  const alphaPattern = /a-zA-Z/;
  return alphaPattern.test(str.charAt(0));
};

export const isUpperCase = (str: string): boolean => {
  return str === str.toUpperCase();
};

export const toTitleCase = (title: string): string => {
  const words = title.trim().split(' ');
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (!isUpperCase(word)) {
      if (i === 0 || !CSL.SKIP_WORDS.includes(word)) {
        words[i] = capitalizeFirstLetter(word);
      }
    }
  }
  return words.join(' ');
};

export const isTitleCase = (title: string): boolean => {
  return title === toTitleCase(title);
};

export const toSentenceCase = (
  title: string,
  nlpTags?: Record<string, string>,
): string => {
  const words = title.trim().split(' ');

  let shouldCapitalizeNext = true;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const shouldModifyWord =
      !isUpperCase(word) &&
      (!hasCapitalsAfterFirstLetter(word) || !isFirstLetterAlpha(word)) &&
      !HONORIFIC_TITLES.includes(word.toLowerCase());

    if (shouldModifyWord) {
      // ignores acronyms and words like McDonalds
      if (shouldCapitalizeNext) {
        words[i] = capitalizeFirstLetter(word);
      } else {
        words[i] = word.toLocaleLowerCase();
      }
    }

    shouldCapitalizeNext =
      (word.includes('!') ||
        word.includes('?') ||
        (word.match(/\./g) || []).length === 1 || // this covers the end of sentences without covering ... or words like U.S.
        word.includes(':') ||
        word === '-') &&
      word !== 'vs.';
  }
  let joinedWords = words.join(' ');

  // check 'entity' type to capitalize.
  if (nlpTags) {
    Object.keys(nlpTags).forEach((nlpTagKey) => {
      if (NLP_TAGS_TO_CAPITALIZE.includes(nlpTags[nlpTagKey])) {
        const caseInsensitivePattern = new RegExp(nlpTagKey, 'gi');
        joinedWords = joinedWords.replace(caseInsensitivePattern, nlpTagKey);
      }
    });
  }
  return joinedWords;
};

export const capitalizeSubtitles = (title: string): string => {
  const words = title.trim().split(' ');
  let shouldCapitalizeNext = false;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    // Ignores acronyms and words like McDonalds
    if (shouldCapitalizeNext) {
      words[i] = capitalizeFirstLetter(word);
    }

    shouldCapitalizeNext = word.includes(':') || word === '-';
  }
  return words.join(' ');
};
