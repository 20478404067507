import { getYear, getMonth, getDate } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import getAuthorNameFromString from './get-author-name-from-string';
import { reportGeneralException } from 'lib/sentry';
import crossrefToCitationType from './crossref-to-citation-type';
import { Author } from 'types/author';
import { removeTags } from './util';

export const cleanCiteprocAuthors = (authors: Author[]): Author[] => {
  if (!authors) {
    return authors;
  }
  return authors.filter((author) => !author.name);
};
const extractCitation = (
  type: 'crossref' | 'google' | 'googleChapter' | 'scraper',
  data: Record<string, any>,
): Record<string, any> => {
  const currentDate = new Date();
  if (data.author) {
    // This code checks the names of the authors to see if they have been encoded properly because
    // certain accents are improperly given by the crossref api, for example:
    // Jiménez becomes JimÃ©nez and Fernández becomes FernÃ¡ndez, so we have to UTF-8 decode these names if they are not already
    // encoded in UTF-8. link for reference: https://doi.org/10.3389/fpsyg.2015.00655

    // the try/catch is a band-aid to the issue that this way of utf-8 decoding the author's given/family name
    // isn't ideal and we expect there to be errors in decoding the names.
    // TODO: find a better way of utf-8 decoding strings like JimÃ©nez and FernÃ¡ndez, ideally without using
    // escape(), because it is deprecated.
    for (const author of data.author) {
      try {
        if (
          typeof author.given !== 'undefined' &&
          encodeURIComponent(author.given) !== author.given
        ) {
          author.given = decodeURIComponent(escape(author.given));
        }
        if (
          typeof author.family !== 'undefined' &&
          encodeURIComponent(author.family) !== author.family
        ) {
          author.family = decodeURIComponent(escape(author.family));
        }
      } catch (err) {
        continue;
      }
    }
  }

  if (Array.isArray(data.title)) {
    data.title = data.title[0];
  }

  if (data?.title && data.title.endsWith('&nbsp')) {
    data.title = data.title.replace('&nbsp', '');
  }

  if (type === 'crossref') {
    return {
      id: uuidv4(),
      title: data.title ? removeTags(data.title.trim()) : '', // we can remove html tags from both the view in the search and in the citation output. e.g. "El Derecho Internacional Interpretado por la Corte Supreme de la Nacion"
      DOI: data.DOI,
      ISSN: data.ISSN,
      author: cleanCiteprocAuthors(data.author),
      issue: data.issue,
      issued: data.issued,
      volume: data.volume,
      publisher: data.publisher,
      'publisher-place': data['publisher-location'], // we are sometimes handling book chapters through crossref
      ISBN: data['ISBN'], // we are sometimes handling book chapters through crossref
      'container-title': data['container-title'] && data['container-title'][0],
      'container-title-short':
        data['short-container-title'] && data['short-container-title'][0],
      URL: data.URL,
      page: data.page,
      accessed: {
        'date-parts': [
          [
            getYear(currentDate),
            getMonth(currentDate) + 1,
            getDate(currentDate),
          ],
        ],
      },
      regularType: crossrefToCitationType(data.type),
    };
  } else if (type === 'google') {
    const volumeInfo = data.volumeInfo;
    let dateArray;
    if (volumeInfo.publishedDate) {
      dateArray = volumeInfo.publishedDate
        .split('-')
        .map((datePart: string, index: number) =>
          index === 0 ? datePart : parseInt(datePart),
        );
    }
    let title = volumeInfo.title;

    if (volumeInfo.subtitle) {
      title = `${title}: ${volumeInfo.subtitle}`;
    }

    return {
      id: uuidv4(),
      title,
      author: (volumeInfo.authors || []).map((author: string) => {
        return getAuthorNameFromString(author);
      }),
      issued: dateArray
        ? {
            'date-parts': [dateArray],
          }
        : undefined,
      language: volumeInfo.language,
      publisher: volumeInfo.publisher,
      abstract: volumeInfo.description,
      ISBN:
        volumeInfo.industryIdentifiers &&
        volumeInfo.industryIdentifiers[0].identifier, // make better
      'number-of-pages': volumeInfo.pageCount,
      accessed: {
        'date-parts': [
          [
            getYear(currentDate),
            getMonth(currentDate) + 1,
            getDate(currentDate),
          ],
        ],
      },
      // URL: volumeInfo.canonicalVolumeLink,
    };
  } else if (type === 'googleChapter') {
    const volumeInfo = data.volumeInfo;
    let dateArray;
    if (volumeInfo.publishedDate) {
      dateArray = volumeInfo.publishedDate
        .split('-')
        .map((datePart: string, index: number) =>
          index === 0 ? datePart : parseInt(datePart),
        );
    }
    let containerTitle = volumeInfo.title;

    if (volumeInfo.subtitle) {
      containerTitle = `${containerTitle}: ${volumeInfo.subtitle}`;
    }
    return {
      id: uuidv4(),
      'container-title': containerTitle,
      'original-author': (volumeInfo.authors || []).map((author: string) => {
        return getAuthorNameFromString(author);
      }),
      issued: dateArray
        ? {
            'date-parts': [dateArray],
          }
        : undefined,
      language: volumeInfo.language,
      publisher: volumeInfo.publisher,
      abstract: volumeInfo.description,
      ISBN:
        volumeInfo.industryIdentifiers &&
        volumeInfo.industryIdentifiers[0].identifier, // make better
      'number-of-pages': volumeInfo.pageCount,
      accessed: {
        'date-parts': [
          [
            getYear(currentDate),
            getMonth(currentDate) + 1,
            getDate(currentDate),
          ],
        ],
      },
    };
  } else if (type === 'scraper') {
    return {
      ...data,
      accessed: {
        'date-parts': [
          [
            getYear(currentDate),
            getMonth(currentDate) + 1,
            getDate(currentDate),
          ],
        ],
      },
    };
  }
  reportGeneralException('Type does not exist', type);
  return {
    error: 'Type does not exist',
  };
};

export default extractCitation;
