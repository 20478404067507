import React from 'react';
import InputsContainer from './inputs-container';
import Button from 'components/common/button';
import { CitationData, CitationFieldKey, Name } from 'types/fields';
import InputNames from './input-names';
import CitationFocusedField from 'types/citation-focused-field';
import { FormFieldGroup } from 'types/citation-forms';
import css from './input-names-container.module.css';

interface InputNamesContainerProps {
  citationId: string;
  keys: CitationFieldKey[];
  fields: FormFieldGroup;
  values: CitationData;
  onChange: (
    citationFieldKey: CitationFieldKey,
    newValue: Name[] | undefined,
  ) => void;
  focusedField: CitationFocusedField | undefined;
}

const InputNamesContainer = (props: InputNamesContainerProps): JSX.Element => {
  const handleClickNew = (
    citationFieldKey: CitationFieldKey,
    isFirstNameField: boolean,
  ): void => {
    const existingValue = props.values[citationFieldKey] as Name[];
    props.onChange(citationFieldKey, [
      ...(existingValue ||
        (isFirstNameField
          ? [{ given: '', family: '', suffix: '', literal: '' }]
          : [])),
      { given: '', family: '', suffix: '', literal: '' },
    ]);
  };
  return (
    <div>
      <InputsContainer>
        <div style={{ width: '100%' }}>
          {props.keys.map((citationFieldKey, index) => {
            const isFirstNameField = index === 0;
            const fieldValue = props.values[citationFieldKey];
            const field = props.fields[citationFieldKey];
            if (!field) return;
            if (!fieldValue && !isFirstNameField) return;
            const isFocused =
              props.focusedField && citationFieldKey === props.focusedField.key;
            return (
              <div key={citationFieldKey}>
                <InputNames
                  isFirstNameField={isFirstNameField}
                  citationId={props.citationId}
                  label={field.label}
                  name={citationFieldKey}
                  value={fieldValue as Name[]}
                  isRequired={field.isRequired}
                  isPrimary={field.isRequired}
                  isFocused={isFocused}
                  onChange={(newValue: Name[] | undefined) =>
                    props.onChange(citationFieldKey, newValue)
                  }
                />
              </div>
            );
          })}
        </div>
      </InputsContainer>
      <div className={css.addButtonsContainer}>
        {props.keys.map((citationFieldKey, index) => {
          const field = props.fields[citationFieldKey];
          if (!field) return;
          const isFirstNameField = index === 0;

          return (
            <div key={citationFieldKey} className={css.addButtonContainer}>
              <Button
                style={{ marginRight: 8 }}
                type="light"
                size="small"
                onClick={() =>
                  handleClickNew(citationFieldKey, isFirstNameField)
                }
              >
                + Add {field.label.toLowerCase()}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InputNamesContainer;
