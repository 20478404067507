import React from 'react';
import Input from 'components/common/input';
import SearchIcon from 'static/svgs/icon/17/search.svg';

interface CitationStylePickerSearchProps {
  searchTerm: string;
  onClose: () => void;
  onChangeSearchTerm: (searchTerm: string) => void;
  style?: React.CSSProperties;
}

const CitationStylePickerSearch: React.FC<CitationStylePickerSearchProps> = (
  props: CitationStylePickerSearchProps,
) => {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    props.onChangeSearchTerm(ev.target.value);
  };
  const handleInputKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Escape') {
      props.onClose();
    }
  };
  return (
    <Input
      onKeyDown={handleInputKeyDown}
      icon={<SearchIcon />}
      autoFocus
      onChange={handleChange}
      placeholder="Search citation styles"
      value={props.searchTerm}
      style={props.style}
    />
  );
};

export default CitationStylePickerSearch;
