import React from 'react';
import citationForms from 'types/citation-forms';
import CitationOutputCitationMissingField from './citation-output-citation-missing-field';
import cx from 'classnames';
import { Citation } from 'redux/modules/citation-module';
import CitationOutputCitationDuplicateField from 'components/citation-output-citation-duplicate-field';
import css from './citation-output-citation-field-warnings.module.css';

interface CitationOutputCitationMissingFieldsProps {
  citation: Citation;
}

const CitationOutputCitationMissingFields: React.FC<
  CitationOutputCitationMissingFieldsProps
> = ({ citation }): JSX.Element => {
  const citationForm = citationForms[citation.citationType];
  const citationFormFieldKeys = Object.keys(citationForm.fields);
  const duplicatePublisherWebsiteName =
    citation.data.publisher && citationFormFieldKeys.includes('container-title')
      ? citation.data.publisher === citation.data['container-title']
      : undefined;
  const missingFieldsObj = citation.requiredFieldsMetadata.missingFields;
  const missingFields = Object.keys(missingFieldsObj);
  const isEmpty = !duplicatePublisherWebsiteName && missingFields.length === 0;

  return (
    <div className={cx(css.outerWrapper, isEmpty && css.empty)}>
      <div className={css.innerWrapper}>
        {missingFields &&
          missingFieldsObj &&
          missingFields.map((field) => (
            <CitationOutputCitationMissingField
              value={field}
              originalKey={missingFieldsObj[field]}
              key={field}
              citationID={citation.data.id}
            />
          ))}
        {duplicatePublisherWebsiteName && (
          <CitationOutputCitationDuplicateField
            originalKey="publisher"
            key={citation.data.publisher}
            citationID={citation.data.id}
          />
        )}
      </div>
    </div>
  );
};

export default CitationOutputCitationMissingFields;
