import CitationTypes from 'types/citation-types';
import reportException from './sentry';

const crossrefToCitationType = (type: string, url?: string): CitationTypes => {
  // search all below examples through the title (not URL or DOI) to test this area of code
  let regularType: CitationTypes = CitationTypes['journalArticle'];
  const citationType = type;
  switch (citationType) {
    case 'book':
    case 'monograph':
    case 'reference-book':
      regularType = CitationTypes['book'];
      break;
    case 'book-part': // refers to sections of a book that aren't chapters (e.g. parts of a preface). Ex: https://www.elgaronline.com/view/9781839100529.00011.xml
    case 'book-chapter':
    case 'reference-entry':
      regularType = CitationTypes['bookChapter'];
      break;
    case 'component': // unpredictable, so default to journal article. Ex: https://www.fulcrum.org/concern/file_sets/7w62f991g#info (book page download page) and https://video.aiaa.org/title/eb15b55d-c783-486f-9ae1-03bf3713947b (video)
    case 'other': // unpredictable, so default to journal article. Ex: http://mr.crossref.org/iPage?doi=10.2752%2F9781472572424%2FGIBSON0016 (book) and https://onlinelibrary.wiley.com/doi/10.1002/9781118839591.ch2 (company homepage)
    case null: // unpredictable, so default to journal article. Ex: https://methods.sagepub.com/video/social-science-research-paradigms-positivism-and-interpretivism (video)
    case 'peer-review': // unclear whether this categorizes to a report, conferencePaper, thesis, or journalArticle, so we play safe. Ex: https://publons.com/publon/30017819/
    case 'journal': // Ex: https://www.hindawi.com/journals/edri/
    case 'journal-issue': // specific issue of a volume of a journal. Ex: https://onlinelibrary.wiley.com/toc/14680432/2016/23/5
    case 'journal-article':
    case 'journal-volume': // hard to find other sites, but the only case found is a landing page for journals. Ex: https://www.budrich-journals.de/index.php/dngps/issue/archive
    case 'dataset': // hard to find other sites, but the only case found seems to be a journal. Ex: https://referenceworks.brillonline.com/entries/sacramentum-mundi/charity-virtue-of-charity-COM_000701
      regularType = CitationTypes['journalArticle'];
      break;
    case 'report':
    case 'report-series':
    case 'posted-content':
      regularType = CitationTypes['report'];
      break;
    case 'proceedings': // Ex: https://systemeconomics.ru/sae-2014-vol-1
    case 'proceedings-article':
      regularType = CitationTypes['conferencePaper'];
      break;
    case 'dissertation':
      regularType = CitationTypes['thesis'];
      break;
    // the case where a doi leads to something that isn't a journal article or book.
    default:
      reportException(`Unable to parse type: ${type} from url: ${url}`);
      regularType = CitationTypes['journalArticle'];
  }
  return regularType;
};

export default crossrefToCitationType;
