import { SearchWebsiteMetadataProgressTask } from 'models/citation-model';
import { useEffect, useMemo } from 'react';

const useSearchWebsiteMetadataProgressTask = () => {
  const searchWebsiteMetadataProgressTask = useMemo(() => {
    return new SearchWebsiteMetadataProgressTask();
  }, []);

  useEffect(() => {
    return () => {
      searchWebsiteMetadataProgressTask.source?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return searchWebsiteMetadataProgressTask;
};

export default useSearchWebsiteMetadataProgressTask;
