import React, { CSSProperties, useRef } from 'react';
import cx from 'classnames';
import InputTextWarning from './input-text-warning';
import { TextCase } from 'types/text-case';
import NameCase from 'lib/namecase';
import InputWarningData from 'types/input-warning-data';
import useInputFocus from 'lib/hooks/useInputFocus';
import { Store } from 'redux/store';
import { useSelector } from 'react-redux';
import { toSentenceCase, toTitleCase } from 'lib/string-formatting';
import css from './input-text.module.css';

interface InputTextProps {
  name?: string;
  value?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  onChange: (newValue: string) => void;
  isRequired?: boolean;
  isPrimary?: boolean;
  isFocused?: boolean;
  hasDuplicateWebsitePublisherName?: boolean;
  textCase?: TextCase;
  style?: CSSProperties;
  placeholder?: string;
  applySuggestion?: (typeSuggest?: string, suggestion?: string) => void;
  specialCase?: string;
}

const handleTextCase = (
  value: string | undefined,
  textCase: TextCase,
  isAPA: boolean,
): InputWarningData | undefined => {
  if (!value) return undefined;
  value = value.trim();
  const lowerCaseValue = value.toLowerCase();
  const upperCaseValue = value.toUpperCase();
  const isAllCaps = upperCaseValue === value;
  const isLowerCase = lowerCaseValue === value;

  switch (textCase) {
    case 'title':
      const titleCaseValue =
        (isAllCaps && value.length > 3) || isLowerCase
          ? isAPA
            ? toSentenceCase(lowerCaseValue) // TODO: use NLP
            : toTitleCase(lowerCaseValue)
          : value;
      if (titleCaseValue !== value) {
        return {
          message: titleCaseValue,
          isSuggestion: true,
          extraMessage: '(Ignore suggestion for acronyms) ',
        };
      }
      break;
    case 'name':
      const nameCaseValue =
        isAllCaps || isLowerCase
          ? NameCase(lowerCaseValue, { individualFields: true })
          : value;
      if (nameCaseValue !== value) {
        return {
          message: nameCaseValue,
          isSuggestion: true,
        };
      }
      break;
  }
};

const InputText: React.FC<InputTextProps> = (
  props: InputTextProps,
): JSX.Element => {
  const currentCitationStyle = useSelector(
    (state: Store) => state.citation.citationStyle,
  );
  const isAPA = currentCitationStyle.name.includes(
    'American Psychological Association',
  );
  const localInputRef = useRef<HTMLInputElement>(null);
  const inputRef = props.inputRef ? props.inputRef : localInputRef;

  let warningData: InputWarningData | undefined;
  if (props.hasDuplicateWebsitePublisherName) {
    warningData = {
      message: `Leave Publisher blank if it's the same as "Website name".`,
      isSuggestion: true,
      extraMessage: '',
      applyClear: true,
    };
  } else if (props.textCase) {
    warningData = handleTextCase(props.value, props.textCase, isAPA);
  }

  useInputFocus(props.isFocused, inputRef);

  return (
    <div className={css.citationInputText}>
      <input
        style={props.style}
        ref={inputRef}
        type="text"
        value={props.value}
        className={cx(warningData && css.inputWarning, css.input)}
        id={props.name}
        name={props.name}
        data-hj-allow
        data-clarity-unmask="true"
        placeholder={
          props.placeholder
            ? props.placeholder
            : props.isRequired
            ? 'Required'
            : ''
        }
        onChange={(ev) => props.onChange(ev.target.value)}
      />
      {warningData && (
        <InputTextWarning
          warningData={warningData}
          specialCase={props.specialCase || ''}
          applySuggestion={props.applySuggestion}
          handleChange={props.onChange}
        />
      )}
    </div>
  );
};

export default InputText;
