import { Name } from 'types/fields';

const suffixes = ['Jr.', 'Sr.'];
const sonsAndDaughters = [
  'von',
  'van',
  'bin',
  'del',
  'ap',
  'al',
  'ben',
  'lo',
  'le',
  'de',
  'te',
];

const getAuthorNameFromString = (author: string): Name => {
  const toReturn: Name = {};
  let authorToUse = author;
  suffixes.forEach((suffix) => {
    if (author.endsWith(` ${suffix}`)) {
      toReturn.suffix = suffix;
    }
    authorToUse = authorToUse.replace(` ${suffix}`, '');
  });
  const nameArray = authorToUse.split(' ');
  let familyName = nameArray[nameArray.length - 1];
  if (
    nameArray.length > 2 &&
    sonsAndDaughters.includes(nameArray[nameArray.length - 2])
  ) {
    familyName = `${nameArray[nameArray.length - 2]} ${familyName}`;
  }
  return {
    ...toReturn,
    ...{
      family: familyName,
      given: authorToUse.replace(familyName, '').trim(),
    },
  };
};

export default getAuthorNameFromString;
