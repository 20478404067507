import { createSelector } from 'reselect';
import { CitationState } from 'redux/modules/citation-module';
import { Store } from 'redux/store';

export const getCitations = ({ citation }: Store): CitationState['citations'] =>
  citation.citations;

const citationInputIndex = ({
  citation,
}: Store): CitationState['citationInputIndex'] => citation.citationInputIndex;

export const getCurrentCitation = createSelector(
  [getCitations, citationInputIndex],
  (citations, citationInputIndex) => {
    return citations[citationInputIndex];
  },
);

export const getIsCitationOpen = createSelector(
  [getCitations, citationInputIndex],
  (citations, citationInputIndex) => {
    return !!citations[citationInputIndex];
  },
);

export const getNewestCitation = createSelector([getCitations], (citations) =>
  citations.length > 0 ? citations[citations.length - 1] : undefined,
);
