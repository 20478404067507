import * as React from 'react';
import { colors, text } from 'css/variables';
import css from './form-label.module.css';

interface FormLabelProps {
  children: React.ReactNode;
  fontWeight?: string | number;
  style?: React.CSSProperties;
  color?: string;
  htmlFor?: string;
}

const FormLabel = ({
  color,
  children,
  htmlFor,
  style,
}: FormLabelProps): JSX.Element => {
  const dynamicStyles = {
    ...style,
    ...(color ? { color } : {}),
  };
  return (
    <>
      <label style={dynamicStyles} htmlFor={htmlFor} className={css.labelSmall}>
        {children}
      </label>
    </>
  );
};

export default FormLabel;
