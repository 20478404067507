export default function updateObjectInArray<T extends object>(
  array: T[],
  action: { index: number; item: T },
): T[] {
  const newArray = [...array];
  if (newArray[action.index]) {
    newArray[action.index] = { ...newArray[action.index], ...action.item };
  }
  return newArray;
}
