import yn from 'yn';

export const calculateParagraphStylesFromBibliographySettings = (
  entryspacing: number,
  hangingindent: boolean,
  linespacing: 1 | 2,
  gdocsExport?: boolean,
): string => {
  const pStyles = [];
  if (hangingindent) {
    if (!yn(process.env.GDOCS) && !gdocsExport) {
      // microsoft word acts differently than gdocs, adding less padding than gdocs
      pStyles.push(`margin-left: ${inToPtString(0.5)};`);
    }
    pStyles.push(`text-indent: ${inToPtString(-0.5)};`);
  }
  pStyles.push(`margin-top: 0em;`);
  pStyles.push(`margin-bottom: ${entryspacing}em;`);

  // CSL is dumb and can only handle integers for line spacing, so all we get is 1 or 2, when a lot of styles are 1.5. TODO: make our own mapping of line spacing for common formats
  pStyles.push(`line-height: ${linespacing === 1 ? 1.5 : 2};`);

  return pStyles.join('');
};

const inToPtString = (inches: number): string => {
  // we have to use this because Google Docs doesn't recognize inches
  return `${inches * 72}pt`;
};
