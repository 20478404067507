import React, { useRef } from 'react';
import InputsContainer from './inputs-container';
import Button from 'components/common/button';
import getPartsFromDate from 'lib/get-parts-from-date';
import useInputFocus from 'lib/hooks/useInputFocus';
import usePrevious from 'lib/hooks/usePrevious';
import FormLabel from './form-label';
import css from './input-date.module.css';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export type DateValue = Record<string, (string | number | undefined)[][]>;

interface InputDateProps {
  citationId: string;
  name?: string;
  value: DateValue;
  onChange: (newValue: DateValue) => void;
  isRequired?: boolean;
  isPrimary?: boolean;
  isFocused?: boolean;
}

const InputDate: React.FC<InputDateProps> = (
  props: InputDateProps,
): JSX.Element => {
  const year = useRef<HTMLInputElement>(null);
  const month = useRef<HTMLSelectElement>(null);
  const day = useRef<HTMLSelectElement>(null);

  const prevCitationId = usePrevious(props.citationId);
  if (prevCitationId !== props.citationId) {
    if (year.current) year.current.value = '';
    if (month.current) month.current.value = '';
    if (day.current) day.current.value = '';
  }

  const handleChange = (): void => {
    if (
      year.current &&
      !(
        Number.isInteger(parseInt(year.current.value || '', 10)) ||
        year.current.value === '-'
      )
    ) {
      year.current.value = '';
    }

    const currentYear = year.current?.value && parseInt(year.current.value);
    const currentMonth = month.current?.value && parseInt(month.current.value);
    const currentDay = day.current?.value && parseInt(day.current.value);
    const dateParts: (string | number | undefined)[] = [];
    dateParts.push(currentYear);
    dateParts.push(currentMonth);
    dateParts.push(currentDay);
    props.onChange({
      'date-parts': [dateParts],
    });
  };

  useInputFocus(props.isFocused, year);

  const handleClickToday = (): void => {
    props.onChange({
      'date-parts': getPartsFromDate(new Date()),
    });
  };

  return (
    <div>
      <InputsContainer>
        <div className={css.inputContainer}>
          <FormLabel htmlFor={`${props.name}-day`}>Day</FormLabel>
          <select
            ref={day}
            id={`${props.name}-day`}
            name={`${props.name}-day`}
            value={
              props.value &&
              props.value['date-parts'] &&
              props.value['date-parts'][0] &&
              props.value['date-parts'][0][2]
            }
            onChange={handleChange}
          >
            <option value={undefined}></option>
            {days.map((day, index) => {
              return (
                <option key={index} value={index + 1}>
                  {day}
                </option>
              );
            })}
          </select>
        </div>
        <div className={css.inputContainer}>
          <FormLabel htmlFor={`${props.name}-month`}>Month</FormLabel>
          <select
            ref={month}
            id={`${props.name}-month`}
            name={`${props.name}-month`}
            value={
              props.value &&
              props.value['date-parts'] &&
              props.value['date-parts'][0] &&
              props.value['date-parts'][0][1] // handling malformed date-parts
            }
            onChange={handleChange}
          >
            <option value={undefined}></option>
            {months.map((month, index) => {
              return (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              );
            })}
          </select>
        </div>
        <div className={css.inputContainer}>
          <FormLabel htmlFor={`${props.name}-year`}>Year</FormLabel>
          <input
            ref={year}
            type="text"
            inputMode="numeric"
            data-hj-allow
            data-clarity-unmask="true"
            placeholder={props.isRequired ? 'Required' : ''}
            id={`${props.name}-year`}
            name={`${props.name}-year`}
            value={
              props.value &&
              props.value['date-parts'] &&
              props.value['date-parts'][0] &&
              props.value['date-parts'][0][0]
            }
            onChange={handleChange}
          />
        </div>
        <div className={css.todayContainer}>
          <Button size="tiny" type="link" onClick={handleClickToday}>
            Today
          </Button>
        </div>
      </InputsContainer>
    </div>
  );
};

export default InputDate;
