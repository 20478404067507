import React from 'react';
import * as CitationActions from 'redux/modules/citation-module';
import { useDispatch, useStore } from 'react-redux';
import { CitationFieldKey } from 'types/fields';
import * as CitationListModel from 'models/citation-list-model';
import css from './citation-output-citation-missing-field.module.css';

interface CitationOutputCitationMissingFieldProps {
  value: string;
  citationID: string;
  originalKey: CitationFieldKey; // the key it corresponds to in CitationData
}

const CitationOutputCitationMissingField: React.FC<
  CitationOutputCitationMissingFieldProps
> = ({ value, citationID, originalKey }): JSX.Element => {
  const dispatch = useDispatch();
  const store = useStore();

  const handleIgnore = async (): Promise<void> => {
    dispatch(
      CitationActions.ignoreCitationMissingField({
        id: citationID,
        field: value,
      }),
    );
    CitationListModel.saveCurrentList(store);
  };

  const handleAdd = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    dispatch(CitationActions.selectCitation(citationID));
    dispatch(CitationActions.focusField(originalKey));
  };

  return (
    <span className={css.field}>
      <span>
        <span className={css.fieldTextIcon}>!</span>
        <span>
          {value}
          <span className={css.fieldTextMissing}> missing</span>
        </span>
      </span>
      <span className={css.fieldOptions}>
        <button
          className={css.fieldOptionsButton}
          onMouseDown={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            e.stopPropagation();
          }}
          onClick={handleIgnore}
        >
          <span>Dismiss</span>
        </button>
        <button
          className={css.fieldOptionsButton}
          onMouseDown={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            e.stopPropagation();
          }}
          onClick={handleAdd}
        >
          <span>Add</span>
        </button>
      </span>
    </span>
  );
};

export default CitationOutputCitationMissingField;
