import React from 'react';
import css from './loader.module.css';

const Loader: React.FC = () => (
  <div className={css.skChase}>
    {Array(6)
      .fill(null)
      .map((_, idx) => (
        <div key={idx} className={css.skChaseDot}></div>
      ))}
  </div>
);

export default Loader;
