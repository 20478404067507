import React from 'react';
import * as CitationActions from 'redux/modules/citation-module';
import { useDispatch } from 'react-redux';
import { CitationFieldKey } from 'types/fields';
import css from './citation-output-citation-duplicate-field.module.css';

interface CitationOutputCitationDuplicateFieldProps {
  citationID: string;
  originalKey: CitationFieldKey; // the key it corresponds to in CitationData
}

const CitationOutputCitationDuplicateField: React.FC<
  CitationOutputCitationDuplicateFieldProps
> = ({ citationID, originalKey }): JSX.Element => {
  const dispatch = useDispatch();

  const handleFix = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    dispatch(CitationActions.selectCitation(citationID));
    dispatch(CitationActions.focusField(originalKey));
  };
  return (
    <span className={css.field}>
      <span className={css.fieldText}>
        <span className={css.fieldTextIcon}>!</span>
        <span className={css.fieldTextText}>
          {originalKey[0].toUpperCase() + originalKey.slice(1)}
          <span className={css.fieldTextMissing}>{' duplicate'}</span>
        </span>
      </span>
      <span className={css.fieldOptions}>
        <button
          className={css.fieldOptionsButton}
          onMouseDown={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            e.stopPropagation();
          }}
          onClick={handleFix}
        >
          <span>Fix</span>
        </button>
      </span>
    </span>
  );
};

export default CitationOutputCitationDuplicateField;
