export function unescape(str: string): string {
  const htmlEntities: Record<string, string> = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
  };

  return str.replace(
    /&amp;|&lt;|&gt;|&quot;|&#39;/g,
    (match) => htmlEntities[match],
  );
}

export function startCase(input: string | undefined): string {
  const words = input?.match(/[a-zA-Z0-9]+/g);
  if (!words) {
    return '';
  }

  return words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}

export function uniqBy(items: any[], key: string): any[] {
  const seen = new Set();
  return items.filter((item) => {
    const itemValue = item[key];
    if (!seen.has(itemValue)) {
      seen.add(itemValue);
      return true;
    }
    return false;
  });
}

export const removeTags = (str: string): string => {
  return str.replace(/<[^>]*>?/gm, '');
};
