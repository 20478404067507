import CitationTypes from 'types/citation-types';
import CSLCitationTypes from 'types/csl-citation-types';
import reportException from './sentry';

const citationTypeToCSLMap: { [key in CitationTypes]: CSLCitationTypes } = {
  [CitationTypes['artwork']]: CSLCitationTypes['graphic'],
  [CitationTypes['blogPost']]: CSLCitationTypes['post-weblog'],
  [CitationTypes['book']]: CSLCitationTypes['book'],
  [CitationTypes['bookReview']]: CSLCitationTypes['review-book'],
  [CitationTypes['bookChapter']]: CSLCitationTypes['chapter'],
  [CitationTypes['conferencePaper']]: CSLCitationTypes['paper-conference'],
  [CitationTypes['database']]: CSLCitationTypes['dataset'],
  [CitationTypes['dictionaryEntry']]: CSLCitationTypes['entry-dictionary'],
  [CitationTypes['ebook']]: CSLCitationTypes['book'],
  [CitationTypes['encyclopediaEntry']]: CSLCitationTypes['entry-encyclopedia'],
  [CitationTypes['filmOrMovie']]: CSLCitationTypes['motion_picture'],
  [CitationTypes['image']]: CSLCitationTypes['graphic'],
  [CitationTypes['interview']]: CSLCitationTypes['interview'],
  [CitationTypes['journalArticle']]: CSLCitationTypes['article-journal'],
  [CitationTypes['legalCase']]: CSLCitationTypes['legal_case'],
  [CitationTypes['legalBill']]: CSLCitationTypes['legislation'],
  [CitationTypes['legislation']]: CSLCitationTypes['legislation'],
  [CitationTypes['magazineArticle']]: CSLCitationTypes['article-magazine'],
  [CitationTypes['map']]: CSLCitationTypes['map'],
  [CitationTypes['newsArticle']]: CSLCitationTypes['article-newspaper'],
  [CitationTypes['patent']]: CSLCitationTypes['patent'],
  [CitationTypes['personalCommunication']]:
    CSLCitationTypes['personal_communication'],
  [CitationTypes['report']]: CSLCitationTypes['report'],
  [CitationTypes['review']]: CSLCitationTypes['review'],
  [CitationTypes['song']]: CSLCitationTypes['song'],
  [CitationTypes['speech']]: CSLCitationTypes['speech'],
  [CitationTypes['thesis']]: CSLCitationTypes['thesis'],
  [CitationTypes['tvRadioBroadcast']]: CSLCitationTypes['broadcast'],
  [CitationTypes['video']]: CSLCitationTypes['video'],
  [CitationTypes['website']]: CSLCitationTypes['webpage'],
  [CitationTypes['document']]: CSLCitationTypes['document'],
  [CitationTypes['manuscript']]: CSLCitationTypes['manuscript'],
};

const citationTypeToCSL = (citationType: CitationTypes): CSLCitationTypes => {
  let cslType = citationTypeToCSLMap[citationType];
  if (cslType === undefined) {
    reportException(
      `No conversion from CitationTypes to CSLCitationTypes exists for: ${citationType}`,
    );
    cslType = CSLCitationTypes['article-journal'];
  }
  return cslType;
};

export default citationTypeToCSL;
