import React from 'react';
import css from './inputs-container.module.css';

interface InputsContainerProps {
  children: React.ReactNode;
}

const InputsContainer: React.FC<InputsContainerProps> = (
  props: InputsContainerProps,
): JSX.Element => {
  return <div className={css.inputsContainer}>{props.children}</div>;
};

export default InputsContainer;
