import { colord, extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';

extend([mixPlugin]);

export function lighten(initialColor: string, lightenBy: number): string {
  return colord(initialColor)
    .lighten(lightenBy / 100)
    .toHex();
}
export function darken(initialColor: string, darkenBy: number): string {
  return colord(initialColor)
    .darken(darkenBy / 100)
    .toHex();
}

export function mixWithWhite(initialColor: string, ratio: number): string {
  return colord(initialColor)
    .mix('#ffffff', 1 - ratio)
    .toHex();
}
