import cx from 'classnames';
import { colors } from 'css/variables';
import copyCitToClipboard from 'lib/copy-citation';
import React, { useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Store } from 'redux/store';
import css from './citation-copy-popup.module.css';

export const CopiedCheck: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg width={10} height={8} fill="none" {...props}>
    <path d="m.5 4 3 3 6-6" stroke="currentColor" strokeLinecap="round" />
  </svg>
);

interface CitationCopyPopupProps {
  citationString: string;
  entryspacing?: number;
  hangingindent?: boolean;
  linespacing?: 1 | 2;
  intext: boolean;
  height?: string;
  width?: string;
}

const CitationCopyPopup: React.FC<CitationCopyPopupProps> = (
  props: CitationCopyPopupProps,
): JSX.Element => {
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  const store = useStore();
  const gdocsExport = useSelector((store: Store) => store.auth.gdocsExport);

  const handleClick = (): void => {
    if (recentlyCopied) {
      return;
    }
    copyCitToClipboard({
      citationString: props.citationString,
      hangingindent: props.hangingindent,
      gdocsExport,
      intext: props.intext,
      linespacing: props.linespacing,
      entryspacing: props.entryspacing,
      store,
    });
    setRecentlyCopied(true);
    setTimeout(() => {
      setRecentlyCopied(false);
    }, 2000);
  };

  const dynamicBackgroundColor = recentlyCopied
    ? 'rgba(51, 183, 135, 0.1)'
    : colors.grey.lighter;

  return (
    <div className={css.citationCopyPopup}>
      <div
        onClick={handleClick}
        tabIndex={0}
        role="button"
        className={css.citationTextOuter}
        style={
          {
            '--dynamic-bg-color': dynamicBackgroundColor,
          } as React.CSSProperties
        }
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            handleClick();
          }
        }}
      >
        <div
          className={cx(
            css.citationHelper,
            recentlyCopied ? css.recentlyCopied : css.notRecentlyCopied,
          )}
        >
          <span className={css.helperText}>
            {recentlyCopied ? (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CopiedCheck />
                <span style={{ marginLeft: 6 }}>Copied</span>
              </span>
            ) : (
              <span>Click to copy</span>
            )}
          </span>
        </div>
        <div
          className={cx(
            css.citationText,
            props.hangingindent
              ? css.withHangingIndent
              : css.withoutHangingIndent,
          )}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: props.citationString,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CitationCopyPopup;
