import { colors } from 'css/variables';
import React from 'react';
import InputWarningData from 'types/input-warning-data';
import { darken } from 'lib/color';
import css from './input-text-warning.module.css';
import cx from 'classnames';

interface InputTextWarningProps {
  warningData: InputWarningData;
  specialCase: string;
  applySuggestion?: (typeSuggest?: string, suggestion?: string) => void;
  handleChange?: (newValue: string) => void;
}

const InputTextWarning: React.FC<InputTextWarningProps> = ({
  warningData,
  specialCase,
  applySuggestion,
  handleChange,
}): JSX.Element => {
  const dynamicBgColor = darken(colors.yellow.light, 15);
  const dynamicBgColorHover = darken(colors.yellow.light, 25);

  if (!warningData.message) return <></>;
  return (
    <div
      className={css.warning}
      style={
        {
          '--dynamic-bg-color': dynamicBgColor,
          '--dynamic-bg-color-hover': dynamicBgColorHover,
        } as React.CSSProperties
      }
    >
      {warningData.isSuggestion && 'Suggested: '} {warningData.message}
      <span className={css.warningExtraMessage}>
        {warningData.extraMessage || ''}
      </span>
      {specialCase === 'family' || specialCase === 'given' ? (
        <button
          className={css.suggestionFixButton}
          onClick={
            applySuggestion && warningData
              ? () => {
                  applySuggestion(specialCase, warningData.message);
                }
              : undefined
          }
        >
          Apply
        </button>
      ) : (
        <button
          className={cx(css.suggestionFixButton)}
          onClick={
            handleChange && warningData
              ? () => {
                  if (warningData.applyClear) {
                    handleChange('');
                  } else {
                    handleChange(warningData.message);
                  }
                }
              : undefined
          }
        >
          Apply
        </button>
      )}
    </div>
  );
};

export default InputTextWarning;
