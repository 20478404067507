import { reportGeneralException } from './sentry';
import * as CitationListModel from 'models/citation-list-model';
import stringReplaceAll from 'string-replace-all';
import * as clipboard from 'clipboard-polyfill';
import Tracking from './tracking';
import { AppStore } from 'redux/store';
import { calculateParagraphStylesFromBibliographySettings } from './paragraphStylesFromBibSettings';

interface fnTypes {
  citationString: string;
  gdocsExport: boolean;
  intext: boolean;
  hangingindent?: boolean;
  linespacing?: 1 | 2;
  entryspacing?: number;
  store?: AppStore;
  enableAnalytics?: boolean;
  trackingMessage?: string;
}

function copyCitToClipboard({
  citationString,
  hangingindent,
  gdocsExport,
  intext,
  linespacing,
  entryspacing,
  store,
  enableAnalytics = true,
  trackingMessage,
}: fnTypes) {
  const renderedText = renderText(citationString);
  const clipboardItemInput: { [key: string]: Blob } = {
    'text/plain': new Blob([renderedText ?? ''], {
      type: 'text/plain',
    }),
  };
  if (intext) {
    clipboardItemInput['text/html'] = new Blob([citationString], {
      type: 'text/html',
    });
  } else if (
    // truthyness check isn't enough here, have to explicitly check for undefined (since 0 == false)
    entryspacing !== undefined &&
    linespacing !== undefined
  ) {
    const citationsString = `<p style="${calculateParagraphStylesFromBibliographySettings(
      entryspacing,
      hangingindent || false, // hangingindent is sometimes undefined rather than false
      linespacing,
      gdocsExport,
    )}">${stringReplaceAll(citationString, '<div', '<span')}</p>`;
    clipboardItemInput['text/html'] = new Blob([citationsString], {
      type: 'text/html',
    });
  } else {
    enableAnalytics
      ? reportGeneralException('Citation settings non-existent', {
          citationString,
          entryspacing,
          hangingindent,
          linespacing,
          gdocsExport,
        })
      : console.log('Citation settings non-existent');
  }
  const item = new clipboard.ClipboardItem(clipboardItemInput);
  clipboard.write([item]);
  if (store) {
    CitationListModel.saveCurrentList(store);
  }
  const trackingType = intext ? 'intext' : 'bib';
  enableAnalytics &&
    Tracking.trackEvent('Outputted citation', {
      type: trackingMessage ?? 'single ' + trackingType + ' clipboard',
    });
}

export function renderText(text: string) {
  const doc = new DOMParser().parseFromString(text, 'text/html');
  return doc.documentElement.textContent;
}

export default copyCitToClipboard;
