import { useEffect } from 'react';

const useInputFocus = (
  focus: boolean | undefined,
  inputRef: React.RefObject<HTMLInputElement>,
): void => {
  useEffect(() => {
    if (focus) {
      inputRef.current?.focus();
    }
  });
};

export default useInputFocus;
